import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Page from "../components/Page"

const DownloadPage = ({ data }) => {
  const { contentfulPage } = data

  return (
    <Page
      {...contentfulPage}
      seoPath="download"
      seoTitle="Rallista - Download"
      seoDescription="Download the Rallista mobile app on iOS or Android."
    />
  )
}

DownloadPage.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({}),
  }),
}

export default DownloadPage

export const pageQuery = graphql`
  query DownloadPageQuery {
    contentfulPage(rallistaId: { eq: "download" }) {
      contentful_id
      rallistaId
      sections {
        ... on ContentfulFeatureSection {
          internal {
            type
          }
          title
          buttonLink
          buttonTitle
          body {
            raw
          }
          justifyImage
          media {
            title
            description
            contentful_id
            file {
              url
              fileName
              contentType
              details {
                size
              }
            }
          }
          contentful_id
        }
        ... on ContentfulHeroSection {
          internal {
            type
          }
          title
          background {
            file {
              contentType
              fileName
              url
              details {
                size
              }
            }
            contentful_id
            title
            description
          }
          titleImage {
            file {
              contentType
              fileName
              url
              details {
                size
              }
            }
            contentful_id
            title
            description
          }
          subTitle
          justifyActionBox
          actionBox {
            buttonLink
            buttonTitle
            body {
              raw
            }
            contentful_id
            title
          }
          contentful_id
        }
        ... on ContentfulTextSection {
          internal {
            type
          }
          id
          title
          richText {
            raw
          }
          contentful_id
        }
        ... on ContentfulDownloadSection {
          internal {
            type
          }
          id
          title
          subTitle
          description {
            raw
          }
          titleImage {
            file {
              contentType
              fileName
              url
              details {
                size
              }
            }
            contentful_id
            title
            description
          }
          appMockupImage {
            file {
              contentType
              fileName
              url
              details {
                size
              }
            }
            contentful_id
            title
            description
          }
        }
      }
    }
  }
`
